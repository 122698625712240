import React from "react";
// import Service from "./Service";

function Englishmain() {
  return (
    <>
      {/* <div className="relative flex flex-col items-center max-w-screen-xl px-4 mx-auto md:flex-row sm:px-6 p-8">
        <div className="flex items-center py-5 md:w-1/2 md:pb-20 md:pt-10 md:pr-10">
          <div className="text-left">
            <span className="font-bold text-blue-500 ">Speak</span>
            <h2 className="text-4xl font-extrabold leading-10 tracking-tight text-gray-800 sm:text-5xl sm:leading-none md:text-6xl">
              English Anywhere Anytime!
            </h2>
            <span className="font-bold text-blue-500">
              Your Journey Begins!
            </span>
            <p className="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Experience the freedom of communicating effortlessly in English,
              no matter where you are. Our expert-led courses and flexible
              learning options empower you to master the language at your own
              pace.
            </p>
          </div>
        </div>
        <div className="flex items-center py-5 md:w-1/2 md:pb-20 md:pt-10 md:pl-10">
          <div className="relative w-full p-3 rounded  md:p-8">
            <div className="rounded-lg bg-white text-black w-full">
              <img src="https://picsum.photos/400/300" />
            </div>
          </div>
        </div>
      </div>
      <Service /> */}
      <div className="english-banner">
        <img
          src="https://newus.in/images/English/banner.jpg"
          alt="https://newus.in/images/English/banner.jpg"
        ></img>
      </div>
    </>
  );
}

export default Englishmain;
